<template>
  <v-dialog modal v-model="show" width="540" v-if="!isAlternate">
    <v-card style="">
      <v-card-title style="font-size: .9em;" class="d-flex justify-space-between pt-2">
        <v-row>
          <v-col cols="11">{{ videoChannelName }}</v-col>
          <v-col cols="1" class="text-right" style="cursor:pointer;" @click="closeVideo()">&#10006;</v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="position: relative;">
        <video
          muted
          style="width: 100%"
          v-if="videoUrl"
          controlsList="nodownload"
          id="video_1"
          @click = "pauseVideo"
          autoplay
          disablePictureInPicture
          @end="handleVideoEnd"
          @timeupdate="handleVideoTimeUpdate"
        >
          <source :src="videoUrl" />
        </video>
        <div class="videoControlsBlock" v-if="showVideoInfo">
          <v-btn fab small style="border-radius: 4px !important; border: 1px solid black;" @click="pauseVideo">
            <v-icon v-if="videoPaused">mdi-play</v-icon>
            <v-icon v-if="!videoPaused">mdi-pause</v-icon>
          </v-btn>
          <v-btn fab small style="margin-left: 170px; border-radius: 4px !important; border: 1px solid black;" @click="muteVideo">
            <v-icon v-if="videoMuted">mdi-volume-off</v-icon>
            <v-icon v-if="!videoMuted">mdi-volume-high</v-icon>
          </v-btn>
        </div>
        <div>
          <v-row v-if="videoCurrentProgram.event">
            <v-col cols="12" class="popupBlockTitle mb-0 pb-0 mt-1">Название телепередачи</v-col>
          </v-row>
          <v-row v-if="videoCurrentProgram.event">
            <v-col cols="12" class="popupBlockContent mt-0 pt-0 mb-3 pb-0">{{videoCurrentProgram.event}}</v-col>
          </v-row>
          <v-row v-if="!videoCurrentProgram.event">
            <v-col class="mb-2 pb-0"></v-col>
          </v-row>
          <v-row v-if="videoCurrentProgram.subject">
            <v-col cols="12" class="popupBlockTitle mb-0 pb-0 mt-n4">Сюжет</v-col>
          </v-row>
          <v-row v-if="videoCurrentProgram.subject">
            <v-col cols="12" class="popupBlockContent mt-0 pt-0 mb-3 pb-0">{{videoCurrentProgram.subject}}</v-col>
            <v-col  cols="12" class="popupBlockContent mt-n1 pt-0 mb-3 pb-0" style="font-weight: 500; font-size: .9em;">{{ renderDates(videoCurrentProgram) }}</v-col>
          </v-row>
          <v-row v-if="!videoCurrentProgram.subject">
            <v-col class="mb-2 pb-0"></v-col>
          </v-row>
          <v-row class="mt-n4 mb-2">
            <v-col cols="2"></v-col>
            <v-col cols="4" class="ml-0 mr-0 pl-0 pr-0 popupBlockTitle">
              Контакты
              <div class="popupBlockContentBig" style="color:black">
                {{ videoCurrentProgram.contacts&& videoCurrentProgram.contacts.toLocaleString() }}
              </div>
            </v-col>
            <v-col cols="4" class="ml-0 mr-0 pl-0 pr-0 popupBlockTitle">
              Привлекательность<br>
              <div class="popupBlockContentBig" style="color: black">
                {{ videoCurrentProgram.appeal }}%
              </div>
            </v-col>
          </v-row>
          <v-row v-if="videoCurrentProgram.description">
            <v-col cols="12" class="popupBlockTitle mb-0 pb-0 mt-n4">Описание программы</v-col>
          </v-row>
          <v-row v-if="videoCurrentProgram.description">
            <v-col cols="12" class="popupBlockContent mt-0 pt-0 mb-3 pb-0">{{videoCurrentProgram.description }}</v-col>
          </v-row>
          <v-row v-if="!videoCurrentProgram.description">
            <v-col class="mb-2 pb-0"></v-col>
          </v-row>
          <v-row v-if="videoCurrentProgram.short_text">
            <v-col cols="12" class="popupBlockTitle mb-0 pb-0">Описание телепередачи</v-col>
          </v-row>
          <v-row v-if="videoCurrentProgram.short_text">
            <v-col cols="12" class="popupBlockContent mt-0 pt-0 mb-4 pb-0" style="font-weight: 500;">{{videoCurrentProgram.short_text}}</v-col>
          </v-row>
          <v-row v-if="!videoCurrentProgram.short_text">
            <v-col class="mb-2 pb-0"></v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.videoTimeBlock {
  background-color: rgba(0, 0, 0, 0.38);
  position: absolute;
  top: 4px;
  left: 28px;
  border-radius: 4px;
  color: #fff;
  padding: 4px 8px;
}
.videoControlsBlock {
  position: absolute;
  left: 50%;
  margin-top: -60px;
  margin-left: -20px;
}
</style>
<script>
import moment from "moment";
export default {
  props: ["shown", "videoChannelId", "dt", "videoChannelName", "program", "valueName", "values"],
  mounted() {},
  data: () => ({
    videoUrl: null,
    videoStartDt: null,
    videoCurrentDt: null,
    videoOffset: null,
    videoEventAdded: false,
    videoPaused: false,
    videoMuted: true,
  }),
  computed: {
    playerAPI() {
      return process.env.VUE_APP_PLAYBACK_URL;
    },
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    showVideoInfo() {
      return this.videoCurrentDt >= this.videoStartDt;
    },
    videoDtLocal() {
      if (!this.videoCurrentDt) return "";
      return moment(this.videoCurrentDt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm:ss");
    },
    videoCurrentValue() {
      const valuesIndex = this.videoCurrentDt?.substring(0, 16);
      const values = this.values?.[valuesIndex];
      if (!valuesIndex || !values) return "";
      return values[0] ?? "";
    },
    videoCurrentValue2() {
      const valuesIndex = this.videoCurrentDt?.substring(0, 16);
      const values = this.values?.[valuesIndex];
      if (!valuesIndex || !values || values.length == 1) return "";
      return values[1] ?? "";
    },
    videoCurrentProgram() {
      const emptyProg = {
        subject: null,
        event: null,
        description: null,
      };
      if (!this.program || !this.videoCurrentDt) return emptyProg;
      return this.program;
    },
    show: {
      get() {
        return this.shown;
      },
      async set(value) {
        if (!value) {
          this.closeVideo();
        }
      },
    },
  },
  methods: {
    renderDates(item) {
      //return `${moment(item.sdate).locale('ru').format('DD.MM.YYYY HH:mm:ss')}`
      return `${moment(item.sdate).locale('ru').format('YYYY.MM.DD HH:mm:ss')} - ${moment(item.edate).locale('ru').format('HH:mm:ss')}`
    },
    pauseVideo() {
      const video = document.getElementById("video_1");
      if (video.paused) {
        video.play();
        this.videoPaused = false;
      } else {
        video.pause();
        this.videoPaused = true;
      }
    },
    muteVideo() {
      const video = document.getElementById("video_1");
      if (video.muted) {
        video.muted = false;
        this.videoMuted = false;
      } else {
        video.muted = true;
        this.videoMuted = true;
      }
    },
    async playVideo() {
      if (!this.dt) {
        this.videoUrl = null;
        return;
      }
      this.videoCurrentDt = "";
      let videoStartDt = this.dt;
      if (this.videoStartDt) videoStartDt = this.videoStartDt;
      this.videoStartDt = videoStartDt;
      console.log(`Requesting data ${videoStartDt}..`, videoStartDt);
      const playerUrl = `${this.playerAPI}player.php?start=${videoStartDt}&tv_id=${this.videoChannelId}&city_id=178&duration=60`;
      const response = await fetch(playerUrl);
      const videoData = await response.json();
      if (!videoData) this.$emit("close");
      this.videoOffset = videoData.offset;
      this.videoUrl = `${this.playerAPI}${videoData.file}#t=${videoData.offset}`;
      setTimeout(() => this.openVideo());
    },
    openVideo() {
      const video = document.getElementById("video_1");;
      video.src = this.videoUrl;
      video.muted = this.videoMuted;
      if (!this.videoEventAdded) {
        video.addEventListener("ended", this.handleVideoEnd);
        this.videoEventAdded = true;
      }
    },
    handleVideoEnd(e) {
      const videoEl = e.target;
      console.log(`currentTime: ${videoEl.currentTime}`);
      this.videoStartDt = moment(this.videoStartDt)
        .add(parseInt(videoEl.currentTime - this.videoOffset), "seconds")
        .format("YYYY-MM-DD HH:mm:ss");
      console.log(this.videoStartDt, videoEl.duration);
      if (videoEl.duration > 5) this.playVideo();
    },
    handleVideoTimeUpdate(e) {
      const videoEl = e.target;
      this.videoCurrentDt = moment(this.videoStartDt).add(
        videoEl.currentTime - this.videoOffset,
        "seconds"
      ).format("YYYY-MM-DD HH:mm:ss");
    },
    closeVideo() {
      console.log("close");
      this.videoEventAdded = false;
      const video = document.getElementById("video_1");
      if (video) video.pause();
      this.videoStartDt = null;
      this.$emit("close");
      video.removeEventListener("ended", this.handleVideoEnd);
    },
  },
  watch: {
    shown(val) {
      //console.log(`shown: ${val}`);
      this.videoUrl = null;
      if (val) {
        this.playVideo();
      }
    },
  },
};
</script>
